.page-title:last-child {
  margin-bottom: 15px;
}

.as-messenger {
    width: 100%;
    display: flex;
    height: 80vh;
    box-shadow: 0 4px 20px rgba(0,0,0, 0.1);
    border-radius: 12px;
    margin: 0 15px;
}

.as-messenger .as-left {
    width: 30%;
    background: white;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: auto;
    flex-shrink: 0;
}
.as-messenger .as-left.as-empty {
  display: none;
}

.as-messenger .as-right {
    width: 70%;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fafafa;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-left: 1px solid #d0d0d0;
}
.as-messenger .as-left.as-empty + .as-right {
  width: 100%;
}

.as-messenger .as-right > p {
    font-size: 16px;
}

.as-conversation {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    color: unset;
    padding: 20px 15px;
    position: relative;
    min-height: 140px;
    flex-shrink: 0;
    border-left: 2px solid transparent;
}

.as-conversation:hover {
    background: #fafafa;
    color: unset;
}
.as-conversation.as-active {
    background: #f0f0f0;
    border-left: 2px solid #00f0bc;
}
.as-conversation.as-active:hover {
    background: #e9e9e9;
}

.as-conversation-header img {
    width: 32px;
    height: 32px;
}

.as-conversation span.sender {
    font-size: 14px;
    margin-left: 8px;
}

.as-conversation p {
    margin-top: 8px;
    font-size: 14px;
    color: #555;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

.as-conversation span.time {
    position: absolute;
    right: 8px;
    bottom: 4px;
    color: #c0c0c0;
}

.as-right .as-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.as-right .as-dialog .as-thread {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 15px;
    opacity: 0;
    transition: opacity 0.3s;
}
.as-right .as-dialog .as-thread.as-visible {
    opacity: 1;
}

.as-right .as-dialog .as-footer-form {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid #f0f0f0;
    background: white;
    padding: 15px 25px;
    flex-shrink: 0;
}

.as-right .as-dialog .as-footer-form input[type="text"] {
    width: 90%;
    height: 100%;
    padding: 10px;
    border: 0;
    outline: 0;
    font-size: 16px;
}

.as-footer-form input::placeholder {
    color:#999;
    opacity:1
}
.as-footer-form input::-moz-placeholder{
    color:#999;
    opacity:1
}

.as-footer-form input:-ms-input-placeholder{
    color:#999
}

.as-footer-form input::-webkit-input-placeholder{
    color:#999
}

.as-right .as-dialog .as-footer-form button[type="submit"] {
    border-radius: 4px;
    padding: 10px 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #212529;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: background .25s;
}
.as-right .as-dialog .as-footer-form button[type="submit"]:hover {
    background: #313539;
}

.as-right .as-dialog .as-thread .as-message {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: default;
}
.as-right .as-dialog .as-thread .as-message:nth-child(n+2) {
    margin-top: 12px;
}
.as-right .as-dialog .as-thread .as-message.as-received {
    padding-left: 10px;
}
.as-right .as-dialog .as-thread .as-message.as-sent {
    padding-right: 10px;
    justify-content: flex-end;
}

.as-right .as-dialog .as-thread .as-message img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-top: auto;
}

.as-right .as-dialog .as-thread .as-message .content {
    font-size: 16px;
    padding: 8px 15px;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    max-width: 70%;
    margin: 0;
}
.as-right .as-dialog .as-thread .as-message.as-received .content {
    background: #f0f0f0;
    margin-left: 8px;
}
.as-right .as-dialog .as-thread .as-message.as-sent .content {
    margin-right: 8px;
    background: rgba(33, 37, 41 , 15%);
    color: white;
}
.as-right .as-dialog .as-thread .as-message .time {
    color: black;
    opacity: 20%;
    transition: opacity 0.3s;
}
.as-right .as-dialog .as-thread .as-message:hover .time {
    opacity: 40%;
}
.as-right .as-dialog .as-thread .as-message.as-received .time {
    margin-left: 10px;
}
.as-right .as-dialog .as-thread .as-message.as-sent .time {
    margin-right: 10px;
}
.as-right .as-dialog .as-thread .as-message.as-template {
    display: none;
}

.as-left .as-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #f0f0f0;
}
.as-left .as-search input {
    width: 90%;
    height: 100%;
    padding: 15px 10px;
    border: 0;
    outline: 0;
    font-size: 16px;
  }
.as-search input::placeholder {
    color:#999;
    opacity:1
}
.as-search input::-moz-placeholder{
    color:#999;
    opacity:1
}

.as-search input:-ms-input-placeholder{
    color:#999
}

.as-search input::-webkit-input-placeholder{
    color:#999
}
.placeholder-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.placeholder-wrapper p {
  font-size: 13pt;
}
.placeholder-wrapper button {
  margin-top: 15px;
}

.as-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 640px;
  justify-content: center;
}

.as-form h2 {
  text-align: center;
  margin-bottom: 24px;
}

.as-form label {
  margin: 0;
  font-size: 16px;
}

.as-field {
  width: 100%;
  height: 42px;
  border: 1px solid #f0f0f0;
  margin-top: 2px;
  padding-left: 15px;
  font-size: 16px;
}

.as-form div:nth-child(n+2) {
  margin-top: 15px;
}

.as-form input::placeholder {
    color:#999;
    opacity:1
}
.as-form input::-moz-placeholder{
    color:#999;
    opacity:1
}

.as-form input:-ms-input-placeholder{
    color:#999
}

.as-form input::-webkit-input-placeholder{
    color:#999
}
.as-form button {
  margin-top: 12px;
}

.as-right {
  position: relative;
}

.as-left {
  position: relative;
  overflow: hidden;
}

.as-left .as-members-wrapper {
  height: calc(100% - 70px);
  overflow: auto;
}

.as-left .as-footer-widget {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #f0f0f0;
  margin: 0;
}
.as-left .as-footer-widget button {
  margin-bottom: 0;
}

.closer {
  position: absolute;
  right: 12px;
  top: 0;
  background: transparent;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  font-size: 24px;
  opacity: 0.5;
}
.closer:hover {
  opacity: 1;
}
.as-notification {
    background: green;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0,0,0, 0.1);
    background: white;
    border-bottom: 5px solid green;
    right: 0 !important;
    left: unset;
    top: unset;
}
.as-notification .rc-notification-notice-close {
  display: none;
}
.as-left .as-member {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  background: white;
  border-bottom: 1px solid #f0f0f0;
  padding-left: 10px;
}
.as-left .as-member.as-active {
    background: #f0f0f0;
    border-left: 2px solid #00f0bc;
}

.as-left .as-member img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 12px;
}
.as-left .as-member span {
  color: #777;
  font-size: 16px;
  font-weight: 400;
}
.as-right .as-member-controls {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #f0f0F0;
  background: white;
  justify-content: center;
  height: 70px;
  gap: 16px;
}
.as-right .as-member-controls button {
  margin: 0;
}
.as-right .as-member-panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: #fcfcfc;
}
.as-right .as-member-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:  column;
  padding-bottom: 80px;
  overflow: auto;
}
.as-right .as-member-body .as-form {
  margin-top: 24px;
  max-width: unset;
  height: unset;
}
.as-form hr {
  width: 100%;
  height: 2px;
  background: #d0d0d0;
  margin-top: 20px;
  margin-bottom: 0;
}
