.history-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p {
    text-align: center;
    font-size: 14pt;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.as-bookmarks {
  border-top: 2px solid black;
  width: 100%;
  margin-top: 24px;
  padding-top: 48px;
}

.as-bookmark-placeholder {
  text-align: center;
  font-size: 16pt;
  margin-top: 180px !important;
}
