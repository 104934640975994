.as-subscription-wrapper {
  background: white;
  display: flex;
  flex-direction: column;
  padding: 24px 36px;
  align-items: center;
  margin-left: 16px;
}
.as-subscription-wrapper.as-message {
  height: 280px;
}

.as-subscription-wrapper .header {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.as-subscription-wrapper .header span:nth-child(1) {
  font-size: 12pt;
}
.as-subscription-wrapper .header span:nth-child(2) {
  font-size: 20pt;
  font-weight: 500;
}
.as-subscription-wrapper .header .badge {
  background: #f0f0f0;
  padding: 8px 16px;
  border-radius: 23051998px;
  font-size: 10pt;
  font-weight: 400;
}
.as-subscription-wrapper .body {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}
.as-subscription-wrapper.as-bordered .body.light-text p {
  font-size: 14pt;
  font-weight: 300;
}

.as-subscription-wrapper .body span {
  font-size: 14pt;
  margin: 8px 0;
}
.as-subscription-wrapper .body span strong {
  padding-right: 4px;
}

.as-subscription-wrapper .footer {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  font-size: 12pt;
}
.as-subscription-wrapper .footer span strong {
  padding-right: 4px;
}
.as-subscription-wrapper .footer .btn {
  margin-top: 12px;
}
.placeholder-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.placeholder-wrapper p {
  font-size: 13pt;
}
.placeholder-wrapper button {
  margin-top: 15px;
}
.subscription-placeholder {
  width: 100%;
  display: flex;
  height: 80vh;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background: white;
}
.as-finder-cancel-trial-link ,
.as-finder-cancel-subscription-link {
  background: transparent;
  outline: 0;
  border: 0;
  margin-top: 18px;
  color: #666;
}
.as-finder-subscription-cancel-request {
  background: transparent;
  outline: 0;
  border: 0;
  margin-top: 18px;
  color: #666;
}
.as-subscription-wrapper.as-bordered {
  border: 5px solid #0cefbc;

  span.title {
    font-weight: 500;
    font-size: 14pt;
  }
  select {
    margin-top: 16px;
    height: 48px;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    border: 3px solid black;
    font-weight: 600;
    cursor: pointer;
    font-size: 1.35em;

    &:focus {
      border-color: #0cefbc;
      outline: 0;
    }
  }

  span.plan-notice {
    font-style: italic;
    margin-top: auto;
  }

  .body {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 375px;
    margin-top: 18px;

    p {
      font-size: 14pt;
      margin: 12px 0;
      color: black;
      text-align: center;
      font-weight: bold;
      width: 100%;
    }

    button {
      margin-top: 14px;
      width: 100%;
      height: 52px;
      border-radius: 6px;
      border: 0;
      outline: 0;
      font-size: 13pt;
      background: black;
      border: 2px solid transparent;
      color: white;

      &:hover {
        color: black;
        background: white;
        border: 2px solid black;
      }
    }
  }
}
p.subscription-price {
  font-size: 14pt;
  margin: 12px 0;
  color: black;
  text-align: center;
  font-weight: bold;
  width: 100%;
}

.as-payment-popup {
  position: absolute;
  top: 60px;
  left: 0px;
  width: 100%;
  height: calc(100vh - 60px);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0;
  background: rgba(0,0,0,0.1);

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }

  &:not(.hidden) {

    opacity: 1;
    transition: opacity .25s;

  }
  .as-popup-content {
    width: 600px;
    height: 600px;
    background: white;
    border-radius: 8px;
  }

  .header {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;

    .title {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      #payment-element {
        margin-top: 16px;
        width: 90%;
      }
    }
  }

  button {
    margin-top: 36px;
    width: 80%;
    height: 52px;
    border-radius: 6px;
    border: 0;
    outline: 0;
    font-size: 13pt;
    background: black;
    border: 2px solid transparent;
    color: white;

    &:hover {
      color: black;
      background: white;
      border: 2px solid black;
    }
  }
}
