.container {
  &.dashboard {
    padding-top: 90px;
  }
  &.listings {
    padding-top: 60px;
  }
  // @media screen and (min-width: 768px) {
  //   width: 100%;
  //   max-width: 1630px;
  // }
  width: 100%;
  max-width: 100%;
}

.container__wrap {
  padding-left: 0;
  // padding-top: 90px;
  padding-top: 0;
  min-height: 100vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    // padding-left: 250px;
    padding-left: 240px;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        // padding-left: 60px;
        padding-left: 55px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}


// hacks
.listings {
  .material-table thead tr th {
    background-color: #fafafa;
    border-top: 1px solid rgba(224, 224, 224, 1)
  }
  .col-md-12, .col-lg-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .btn {
    margin-bottom: 0;
    margin-right: 0;
  }
  .dropdown-item {
    font-size: 14px;
    color: #646777;
  }
  .MuiTablePagination-selectRoot {
    display: none;
  }
}

.react-select {
  width: 100%;
}