.page-title:last-child {
  margin-bottom: 15px;
}

.as-messenger {
    width: 100%;
    display: flex;
    height: 80vh;
    box-shadow: 0 4px 20px rgba(0,0,0, 0.1);
    border-radius: 12px;
    margin: 0 15px;
}

.as-messenger .as-left {
    width: 30%;
    background: white;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: auto;
    flex-shrink: 0;
}
.as-messenger .as-left.as-empty {
  display: none;
}

.as-messenger .as-right {
    width: 70%;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fafafa;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-left: 1px solid #d0d0d0;
}
.as-messenger .as-left.as-empty + .as-right {
  width: 100%;
}

.as-messenger .as-right > p {
    font-size: 16px;
}

.as-conversation {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    color: unset;
    padding: 20px 15px;
    position: relative;
    min-height: 140px;
    flex-shrink: 0;
    border-left: 2px solid transparent;
}

.as-conversation:hover {
    background: #fafafa;
    color: unset;
}
.as-conversation.as-active {
    background: #f0f0f0;
    border-left: 2px solid #00f0bc;
}
.as-conversation.as-active:hover {
    background: #e9e9e9;
}

.as-conversation-header img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.as-conversation span.sender {
    font-size: 14px;
    margin-left: 8px;
}

.as-conversation p {
    margin-top: 8px;
    font-size: 14px;
    color: #555;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

.as-conversation span.time {
    position: absolute;
    right: 8px;
    bottom: 4px;
    color: #c0c0c0;
}

.as-right .as-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.as-right .as-dialog .as-thread {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 15px;
    opacity: 0;
    transition: opacity 0.3s;
}
.as-right .as-dialog .as-thread.as-visible {
    opacity: 1;
}

.as-right .as-dialog .as-footer-form {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid #f0f0f0;
    background: white;
    padding: 15px 25px;
    flex-shrink: 0;
}

.as-right .as-dialog .as-footer-form input[type="text"] {
    width: 90%;
    height: 100%;
    padding: 10px;
    border: 0;
    outline: 0;
    font-size: 16px;
}

.as-footer-form input::placeholder {
    color:#999;
    opacity:1
}
.as-footer-form input::-moz-placeholder{
    color:#999;
    opacity:1
}

.as-footer-form input:-ms-input-placeholder{
    color:#999
}

.as-footer-form input::-webkit-input-placeholder{
    color:#999
}

.as-right .as-dialog .as-footer-form button[type="submit"] {
    border-radius: 4px;
    padding: 10px 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #212529;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: background .25s;
}
.as-right .as-dialog .as-footer-form button[type="submit"]:hover {
    background: #313539;
}

.as-right .as-dialog .as-thread .as-message {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: default;
}
.as-right .as-dialog .as-thread .as-message:nth-child(n+2) {
    margin-top: 12px;
}
.as-right .as-dialog .as-thread .as-message.as-received {
    padding-left: 10px;
}
.as-right .as-dialog .as-thread .as-message.as-sent {
    padding-right: 10px;
    justify-content: flex-end;
}

.as-right .as-dialog .as-thread .as-message img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-top: auto;
}

.as-right .as-dialog .as-thread .as-message .content {
    font-size: 16px;
    padding: 8px 15px;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    max-width: 70%;
    margin: 0;
}
.as-right .as-dialog .as-thread .as-message.as-received .content {
    background: #f0f0f0;
    margin-left: 8px;
}
.as-right .as-dialog .as-thread .as-message.as-sent .content {
    margin-right: 8px;
    background: rgba(33, 37, 41 , 15%);
    color: white;
}
.as-right .as-dialog .as-thread .as-message .time {
    color: black;
    opacity: 20%;
    transition: opacity 0.3s;
}
.as-right .as-dialog .as-thread .as-message:hover .time {
    opacity: 40%;
}
.as-right .as-dialog .as-thread .as-message.as-received .time {
    margin-left: 10px;
}
.as-right .as-dialog .as-thread .as-message.as-sent .time {
    margin-right: 10px;
}
.as-right .as-dialog .as-thread .as-message.as-template {
    display: none;
}

.as-left .as-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #f0f0f0;
}
.as-left .as-search input {
    width: 90%;
    height: 100%;
    padding: 15px 10px;
    border: 0;
    outline: 0;
    font-size: 16px;
  }
.as-search input::placeholder {
    color:#999;
    opacity:1
}
.as-search input::-moz-placeholder{
    color:#999;
    opacity:1
}

.as-search input:-ms-input-placeholder{
    color:#999
}

.as-search input::-webkit-input-placeholder{
    color:#999
}
.placeholder-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.placeholder-wrapper p {
  font-size: 13pt;
}
.placeholder-wrapper button {
  margin-top: 15px;
}
.inmails-placeholder {
    width: 100%;
    display: flex;
    height: 80vh;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background: white;
}


.as-attachment-picker {
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: darkblue;
    background: #f0f0f0;
    border-radius: 50%;
    border: 0;
    color: black;
    cursor: pointer;
    transition: background 0.3s;
}

.as-attachment-picker svg {
    width: 21px;
}

.as-attachment-picker:hover {
    background: #d0d0d0;
}

.as-thread-loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

@keyframes spin {
    from { transform: rotate(0); }
    to { transform: rotate(180deg); }
}

.as-thread-loader svg {
    width: 36px;
    height: 36px;
    animation: spin 1s infinite linear;
}

.as-thread-loader-message {
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #f0f0f0;
    padding: 16px 24px;
    display: flex;
    position: absolute;
    border-radius: 12px;
}

.as-thread-loader-message p {
    font-size: 18px;
    text-align: center;
    color: black;
}
