.as-welcome-finder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .as-welcome-title {
    font-weight: bolder;
    font-size: 32px;
    text-align: center;
  }

  p {
    margin-top: 24px;
    font-size: 24px;
    max-width: 760px;
    text-align: center;
  }

  a.btn {
    margin-top: 24px;
    font-size: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  .sub-links {
    display: flex;
    flex-direction: column;

    a {
      text-align: center;
      font-size: 18px;
    }

    a:nth-child(n+2) {
      margin-top: 16px;
    }
  }
}
