@import 'settings/variable';  //themes' colors

//vendor...
@import './vendor';

//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding';         // styles of base elements
@import './base/typography';          // base styles of h1-h6, p, span

//components...
@import './component/alert';
@import './component/btn';
@import './component/calendar';
@import './component/card';
@import './component/carousel';
@import './component/catalog_item';
// @import './component/chat';
@import './component/check-box';
@import './component/collapse';
@import './component/color-picker';
@import './component/date-picker';
@import './component/dropdown';
@import './component/dropzone';
@import './component/edit-table';
@import './component/form';
// @import './component/gallery';
@import './component/grid';
// @import './component/inbox';
@import './component/list';
@import './component/map';
@import './component/material-form';
@import './component/modal';
@import './component/notification';
@import './component/pagination';
@import './component/panel';
@import './component/popover';
// @import './component/pricing-card';
// @import './component/product-gallery';
@import './component/progress-bar';
// @import './component/project-member';
@import './component/radio-btn';
@import './component/react-select';
// @import './component/scroll';
// @import './component/search-result';
@import './component/select-options';
@import './component/sidebar';
// @import './component/slider';
@import './component/table';
@import './component/tabs';
// @import './component/text-editor';
@import './component/time-picker';
// @import './component/timeline';
@import './component/todo';
@import './component/toggle-btn';
@import './component/tooltip';
@import './component/topbar';
@import './component/wizard';
// @import './component/email-confirmation';
@import './component/material-table';
@import './component/load';
// @import './component/customizer';
@import './component/input.scss';

//pages...
@import 'containers/account';
@import 'containers/dashboard';
@import 'containers/invoice';
@import 'containers/project-summary';
@import 'containers/profile';
@import 'containers/inmails';
@import 'containers/team';
@import 'containers/subscription-finder';
@import 'containers/dashboard-finder';

//objects...
@import 'objects/layout.scss';


// .rdt_Table {

// }
// .rdt_TableRow {

// }
// .rdt_TableCol {

// }
// .rdt_TableCol_Sortable {

// }
.rdt_TableCell {
    color: #646777
}
// .rdt_TableHeader {

// }
// .rdt_TableFooter {

// }
.rdt_TableHead {
    .FeitL {
        font-size: 14px;
        color: #646777
    }
}
// .rdt_TableHeadRow {

// }
// .rdt_TableBody {

// }
// .rdt_ExpanderRow {

// }
.as-finder-link {
  height: 38px;
  width: 100%;
  text-align: center;
  margin-top: 16px;
  background-color: #0CEFBC;
  border-radius: 4px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  font-weight: bold;
  color: #273644;
  max-width: 70%;

  span {
    margin-right: 4px;
    font-weight: 900;
    font-size: 8px;
    padding-top: 2px;
  }

  &:hover {
    color: #273644;
    transform: scale(1.05);
  }
}
.as-badge-new {
  font-size: 8pt;
  border-radius: 23051998px;
  padding: 4px 12px;
  text-align: center;
  color: #273644;
  background: #01efba;
  font-weight: 400;
  position: absolute;
  right: 6px;
  top: -4px;
}
.as-finder-upsell {
  padding: 12px 24px !important;
  border-radius: 8px !important;
  margin-bottom: 12px !important;
  font-size: 12pt !important;
  background: #41a4fc !important;
  font-weight: 400 !important;
  color: white !important;
}
.as-finder-upsell a {
  text-decoration: underline;
  color: white;
}
.as-invoice-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;

  .as-invoice {
    background: white;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 16px 42px;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0,0,0, 0.1);
    cursor: pointer;
    text-align: center;
    border: 2px solid transparent;

    &:hover {
      border-color: #01efba;
    }

    svg {
      width: 42px;
      margin-bottom: 8px;
    }

    .invoice-reference {
      font-weight: 600;
    }

    .invoice-date {
      font-size: 12px;
      margin-top: 16px;
      color: gray;
    }
  }
}
.as-invoices {
    width: 100%;
    display: flex;
    height: 80vh;
    margin: 0 15px;
}
.as-has-tooltip {
  position: relative;
  overflow: visible;

  &:before, &:after {
    display: none;
  }

  .as-tooltip {
    position: absolute;
    top: -70px;
    left: -40px;
    background: white;
    border-radius: 8px;
    padding: 8px 24px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
    color: black;
    width: 200px;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
  }

  &:hover {
    .as-tooltip {

    opacity: 1;
    }
  }
}
.as-bookmark-list.active {
  background: #01efba;

  span {
    color: #273644;
  }
}

.as-import-intro {
  font-size: 18px;
}

.as-import-options, .as-import-types {
  margin-top: 24px;
}
.as-import-options.uploaded {
  margin-top: 0;
}

.as-import-option {
  .dashboard__card-widget {
    border: 2px solid transparent;
    & > div  {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        width: 48px;
      }

      .card__title {
        margin: 20px;

        h5 {
          font-size: 18px;
          text-transform: none;
          color: black;
        }
      }

      .card__body p {
        text-align: center;
        font-size: 16px;
      }
    }
  }

  &:hover {
    cursor: pointer;
    .dashboard__card-widget  {

      background: #fafafa;
      border-color: #01efba;
    }
  }
}

.as-import-dropzone {
  .dashboard__card-widget {
    border: 2px dashed lightgray;
    background: #fafafa;

    & > div  {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 48px;

      svg {
        width: 64px;
        color: lightgray;
      }

      .card__title {
        margin-top: 30px;
        margin-bottom: 8px;

        h5 {
          font-size: 18px;
          text-transform: none;
          color: black;
          font-weight: 400;
        }
      }

      .card__body p {
        text-align: center;
        font-size: 16px;
      }
    }

    textarea {
      border: 0;
      outline: 0;
      width: 100%;
      height: 240px;
      padding: 24px;
      font-size: 18px;
      resize: none;
      overflow: auto;

      &::placeholder {
        color: gray
      }
    }
  }

  &:hover {
    cursor: pointer;
    .dashboard__card-widget  {

      background: #fafafa;
      border-color: #01efba;

      > div {
        svg {
          color: #01Efba;
        }
      }
    }
  }
}

.as-import-success {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 18px;
  border: 2px solid #13ce66;
  background-color: #effaf7;
  position: relative;

  &.as-next {

  margin-top: 18px;
  }


  h5 {
    font-size: 18px;
    color: #333;
  }

  p {
    margin-top: 8px;
  }

  .as-cancel-link {
    background: transparent;
    outline: 0;
    border: 0;
    text-decoration: underline;
    position: absolute;
    right: 24px;
    top: 24px;
  }
}

.as-import-overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  overflow-x: auto;

  p {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }

  table.as-table {
    margin-top: 8px;
    white-space: nowrap;
    width: 100%;

    thead {
      tr {
        th {
          font-weight: 400;
          color: #3c4858;
          font-size: 16px;
          padding: 10px 16px;
          background: #f0f0f0;
          border: 1px solid #cfcfcf;
        }
      }
    }

    tbody {
      tr {
        td {
          font-weight: 400;
          padding: 4px;
          font-size: 14px;
          border: 1px solid #cfcfcf;
          padding: 6px 16px;
        }
      }
    }
  }
}

.as-import-confirm-btn {
  margin-top: 18px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.as-flex-column {
  display: flex;
  flex-direction: column;
}

.as-import-mapping {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  overflow-x: auto;
  padding-bottom: 36px;

  p {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }

  .as-mapping-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 18px 12px;
  }

  .as-mapping-rows {
    display: flex;
    flex-direction: column;
    width: 98%;
    padding-left: 2%;
    gap: 12px;

    .as-mapping-row {

      padding: 36px 12px;
      background: #f9fafc;
      border: 1px solid #c0ccda;
      border-radius: 8px;
      align-items: center;
    }
  }

  .as-cell {
    font-size: 16px;

    &.multi {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    &.input {
      select {
        height: 42px;
        border-radius: 6px;
        width: 100%;
        padding-left: 8px;
      }
    }
  }
}
.as-imports-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 8px;

  .as-import {
    width: 100%;
    height: 80px;
    border-radius: 8px;
    background: white;
    box-shadow: 0 6px 10px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    cursor: pointer;
    border: 2px solid transparent;

    .as-import-cell {
      display: flex;
      align-items: center;
      height: 100%;

      &.for-name {
        font-weight: bold;
        font-size: 16px;
        width: 360px;

        svg {
          height: 24px;
          margin-right: 4px;
        }

        small {
          font-style: italic;
          font-size: 10pt;
        }

        &.for-vision {
          width: 260px;
          svg {
            height: 28px;
            margin-right: 8px;
          }
        }
      }

      &.for-date {
        font-style: italic;
      }

      &.for-button {
        a {
          margin-bottom: 0;
        }
      }
    }

    &:hover {
      border-color: #01efba;

      .as-import-cell {
        color: #273644;
      }
    }
  }
}
.as-import-settings {
  margin-top: 18px;
  p {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }

  .wrapper {
    margin-top: 12px;
  }

  .as-import-setting {
    display: flex;
    align-items: center;
    // width: 800px;

    span {
      width: 50%;
      display:  flex;
      height: 42px;
      border: 1px solid lightgray;
      align-items: center;
      justify-content: center;

      &:nth-child(1) {
        background: #f0f0f0;
        font-weight: bold;
      }
    }
  }
}

.as-import-mappings-settings {
  margin-top: 18px;

  p {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }

  .wrapper {
    margin-top: 12px;
  }


  table {
    width: 100%;
    th {
      padding-top: 10px;
      padding-bottom: 10px;
      border: 1px solid lightgray;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: #f0f0f0;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      border: 1px solid lightgray;
      background: transparent;

    }
  }
}
.as-full-import {
  padding-bottom: 24px;
}
.as-import-shops-overview {
  // margin-top: 36px;
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }

  table.as-table {
    tbody {
      tr {
        td {
          vertical-align: middle;
          .as-img-wrap {
            border: 1px solid #01efba;
            overflow: hidden;
              border-radius: 50%;

            .as-img {
              width: 36px;
              height: 36px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-position: center center;
              background-size: cover;
            }
          }

          button {
            width: 110px;
            height: 30px;
            padding: 0;
          }

          .as-dummy-wrapper {
            display: flex;
            align-items: center;
            gap: 32px;
          }
        }
      }
    }
  }
}
.as-imported-shop-form-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity .3s;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .as-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
  }

  .as-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 760px;
    background: white;
    height: 100%;
    max-height: 500px;
    position: relative;
    border-radius: 8px;
    padding-top: 24px;

    span {
      font-size: 22px;
      font-weight: bold;

    }

    p {
      font-size: 18px;
      margin-top: 4px;
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      overflow: auto;
      width: 80%;
      height: 500px;
      align-items: center;
      margin-top: 24px;
      gap: 12px;

      .as-import-fields-picker {
        display: flex;
        justify-content: center;
        width: 100%;

        button {
          width: 35%;
          height: 46px;
          background: white;
          border: 1px solid #e0e0e0;
          font-weight: 600;
          color: #273644;
          transition: background 0.3s;
          cursor: pointer;

          &:nth-child(1) {
            border-top-left-radius: 23051998px;
            border-bottom-left-radius: 23051998px;
            border-right: 1px solid lightgray;

            &:not(.active) {
              border-right: 0;
            }
          }
          &:nth-child(2) {
            border-left: 0;
            border-top-right-radius: 23051998px;
            border-bottom-right-radius: 23051998px;

            &.active {
              border-left: 1px solid;
            }
          }

          &:hover {
            background: #f2f2f2;
          }

          &.active {
            border-color: #273644;
            background: #01efba;
          }
        }
      }

      .as-import-fields-group {
        gap: 12px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        width: 100%;
        margin-top: 18px;

        &.for-ca {
          gap: 20px;

          .btn {
            margin-bottom: 0;
          }

          div {
            label {
              width: 360px;

              &[contenteditable="true"] {
                border: 1px dashed gray;
                padding-left: 4px;
                padding-top: 4px;
                padding-bottom: 4px;
              }
            }
          }
        }

        div {
          display: flex;
          align-items: center;
          width: 100%;

          label {
            flex-shrink: 0;
            margin: 0;
            width: 180px;
          }

          input {
            height: 40px;
            margin-left: 8px;
            width: 100%;
            flex-shrink: 1;
            padding: 0 12px;

            &::placeholder {
              color: lightgray;
            }
          }
        }
      }
    }

    .as-footer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 12px 0;
      margin-top: 12px;
      gap: 24px;

      button {
        margin: 0;
      }
    }
  }
}
.as-finder-import-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #f0f0F0;
  padding: 36px;
  margin-top: 18px;
  gap: 16px;

  button {
    margin-bottom: 0;
  }
}
.as-import-shops-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }
}
.as-finder-field-searcher {
  height: 70px;
  width: 60%;
  margin-top: 16px;
  padding: 0 12px;

  &::placeholder {
    color: gray;
  }
}
.as-finder-vision {

  select {
    width: 100%;
    height: 45px;
    padding-left: 8px;
    border-radius: 4px;
    outline: 0;
    border: 1px solid lightgray;

    &:hover {
      cursor: pointer;
      border-color: gray;
    }
  }
}
.as-import-method-embed {
  margin-top: 18px;
  padding-left: 0;
  padding-right: 0;
}
.as-flex-col {
  display: flex;
  flex-direction:  column;
}
.as-ta-right {
  text-align: right !important;
}
.as-flex {
  display: flex;
  &.as-align-center {
    align-items: center;
  }
  &.as-space-between {
    justify-content: space-between;
  }
}
.as-finder-file-group-input {
  height: 100%;
  display: flex;
  gap: 4px;

  button {
    width: 120px;
    height: 100%;
    outline: 0;
    border: 0;
    background: #e0e0e0;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background .3s;

    &:hover {

      background: #d0d0d0;
    }
  }
}
.as-finder-vision-metrics {
  gap: 16px;

  @media (min-width: 1200px) {
    .col-xl-3 {
      max-width: 24%;
    }
  }
}
.as-distributors-text-summary {
  font-size: 14pt;
}
.as-distributors-search-fields {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 12px;
  padding-right: 28px;

  > div {
    min-width: 160px;

    * {

      cursor: pointer;
    }
  }

  .as-distributors-search-bar-wrapper {
    margin-left: auto;
    position: relative;

    svg {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 8px;
      top: 10px;
      z-index: 2;
      color: hsl(0, 0%, 50%);
    }

    input {
      z-index: 1;
      align-items: center;
      background-color: hsl(0, 0%, 100%);
      border-color: hsl(0, 0%, 80%);
      cursor: text;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      min-height: 38px;
      outline: 0 !important;
      position: relative;
      transition: all 100ms;
      box-sizing: border-box;
      padding: 0 12px;
      padding-left: 30px;
      min-width: 260px;

      &::placeholder {
        color: hsl(0,0%,50%);
      }

      &:focus {
        border-color: #2684FF;
        border-width: 2px;
      }
    }
  }
}

.as-distributors-results {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 12px;
  margin-top: 24px;
  padding-bottom: 36px;
  padding-right: 20px;

  .as-distributor {
    width: calc(33.33% - 12px);
    background: white;
    border-radius: 8px;
    border: 1px hsl(0, 0%, 10%);
    display: flex;
    flex-direction: column;
    padding: 24px;
    border: 2px solid hsl(0,0%, 90%);

    &:hover {
      border-color: #2684FF;
      cursor: pointer;

      .as-footer {
        .as-cols {
          .as-metric {

            .as-label {
              color: black;
            }
          }
        }
      }
    }

    .as-header {
      display: flex;
      align-items: center;
      width: 100%;

      .as-left {
        display: flex;
        align-items: center;
      }

      .as-icon-wrapper {
        border-radius: 50%;
        padding: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        // background: rgba(0, 0, 0, 0.05);
        background: rgba(38, 132, 255, 0.05);
        overflow: hidden;
        width: 64px;
        height: 64px;

        svg {
          width: 24px;
        }

        img {
          width: 90%;
          height: 90%;
          object-fit: contain;
          border-radius: 50%;
        }

      }

      span {
        font-weight: bold;
        font-size: 12pt;
      }
    }

    .as-body {
      padding-top: 24px;
      padding-bottom: 12px;
      width: 100%;
      p {
        font-size: 10pt;
      }

    }

    .as-footer {
      margin-top: 20px;
      width: 100%;

      .as-cols {

        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .as-metric {
          display: flex;
          flex-direction: column;

          .as-label {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 9pt;
            color: #666;
          }

          .as-value {
            margin-top: 2px;
          }
        }
      }
    }
  }
}
.as-distributors-loading-placeholder, .as-distributors-empty-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;

  p {
    font-size: 14pt;
    text-align: center;
  }
}
.as-back-button {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  outline: 0;
  border: 0;
  float: left;
  margin-top: -2px;
  margin-right: 4px;
  font-weight: bolder;
  transition: background-color 0.3s;

  svg {
    width: 80%;
    color: #646777;
  }

  &:hover {
    background: #eaeaea;
  }
}

.as-single-distributor {
  &.as-for-header {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 14px;

    .as-left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 92px;
      height: 92px;

      .as-icon-wrapper {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(38, 132, 255, 0.05);
        overflow: hidden;
        width: 100%;
        height: 100%;

        svg {
          width: 50%;
        }

        img {
          width: 90%;
          height: 90%;
          object-fit: contain;
          border-radius: 50%;
        }
      }
    }

    .as-right {
      padding-left: 14px;
      display: flex;
      flex-direction: column;

      .as-distributor-name {
        font-weight: bold;
        font-size: 14pt;
      }
      .as-distributor-address {
        display: flex;
        align-items: center;
        svg {
          width: 18px;
          margin-right: 2px;
        }
      }
    }
  }

  &.as-for-ceo {
    display: flex;
    background: white;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
    flex-direction: column;

    h3 {
      font-weight: 500;
      font-size: 13pt;
      color: #333;
    }

    .as-cols {
      display: flex;
      align-items: center;
      margin-top: 16px;
      gap: 0;
    }

    .as-icon-wrapper {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(38, 132, 255, 0.05);
      overflow: hidden;
      margin-right: 8px;
      width: 46px;
      height: 46px;

      svg {
        width: 24px;
      }

      img {
        width: 100%;
        border-radius: 50%;
      }
    }
  }

  &.as-for-metrics {
    display: flex;
    background: white;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
    flex-direction: column;
    margin-top: 18px;

    h3 {
      font-weight: 500;
      font-size: 13pt;
      color: #333;
      align-items: center;
      display: flex;

      svg {
        width: 24px;
        margin-right: 4px;
      }
    }

    .as-metrics {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .as-metric {
        display: flex;
        flex-direction: column;

        .as-label {
          text-transform: uppercase;
          font-size: 9pt;
          color: #666;
          font-weight: 600;
        }
        .as-value {
          color: black;
          font-weight: 600;
          font-size: 11pt;
        }
      }
    }
  }

  &.as-for-presentation {
    display: flex;
    background: white;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
    flex-direction: column;
    margin-top: 18px;
    margin-bottom: 24px;

    h3 {
      font-weight: 500;
      font-size: 13pt;
      color: #333;
      align-items: center;
      display: flex;

      svg {
        width: 24px;
        margin-right: 4px;
      }

      &:nth-of-type(n+2) {
        margin-top: 24px;
      }
    }

    p {
      font-size: 13pt;
      color: #333;
      white-space: pre-wrap;
    }

    .as-for-brands {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 4px;
    }

    .as-brand-pill {
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 23051998px;
      background: rgba(38, 132, 255, 0.05);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding-top: 2px;
      padding-bottom: 2px;
      cursor: default;
      border: 1px solid rgba(38, 132, 255, 0.1);
    }

    ul  {
      margin-top: 4px;
      a {
        text-decoration: underline;
      }
    }
  }
}
.as-add-custom-field-btn {
  border-radius: 23051998px;
  background: transparent;
  border: 1px solid lightgray;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  color: lightgray;
  flex-shrink: 0;

  svg {
    width: 70%;
  }

  &:hover {
    color: black;
    border-color: black;
  }
}

/* INSIGHTS - 2024 */
#root > div > div > div > main > div > div.container__wrap > div > div:nth-child(2) > div.col-12.col-md-12.col-lg-4.col-xl-4 {
  height: 630px !important;
}
#root > div > div > div > main > div > div.container__wrap > div > div:nth-child(2) > div.col-12.col-md-12.col-lg-4.col-xl-4 > div {
  height: 100%;
}
#root > div > div > div > main > div > div.container__wrap > div > div:nth-child(2) > div.col-12.col-md-12.col-lg-4.col-xl-4 > div .panel__title {
  display: none;
}
#root > div > div > div > main > div > div.container__wrap > div > div:nth-child(2) > div.col-12.col-md-12.col-lg-4.col-xl-4 > div .collapse.show {
  height: 100%;
}
#root > div > div > div > main > div > div.container__wrap > div > div:nth-child(2) > div.col-12.col-md-12.col-lg-4.col-xl-4 > div .panel__content {
  height: 100%;
}
#root > div > div > div > main > div > div.container__wrap > div > div:nth-child(2) > div.col-12.col-md-12.col-lg-4.col-xl-4 > div .dashboard__stat.dashboard__stat--budget {
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
}
#root > div > div > div > main > div > div.container__wrap > div > div:nth-child(2) > div.col-12.col-md-12.col-lg-4.col-xl-4 > div .panel__body {
  height: 100%;
}
#root > div > div > div > main > div > div.container__wrap > div > div:nth-child(2) > div.col-12.col-md-12.col-lg-4.col-xl-4 > div > div > div.panel__btns {
  display: none;
}
.as-study-title {
  color: black !important;
  font-size: 16pt;
}
.as-study-intro {
  color: #333 !important;
  font-size: 14pt;
}
.as-study-backlink {
  font-size: 11pt;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.as-remove-controls .panel__btns {
  display: none;
}
.as-restitution {
  margin-top: 24px;
}
.as-restitution .as-is-section .panel__body {
  background: #19f0be;
}
.as-restitution .as-is-section .panel__title {
  margin: 0;
  text-transform: unset;
}
.as-restitution .panel:not(.as-is-section) .panel__title {
  pointer-events: none;
  height: 45px;
}
.as-restitution .as-is-section h5 {
  color: black;
  font-size: 16pt;
  text-transform: unset;
}
.as-restitution .as-is-question h5 {
  color: #333;
  font-size: 13pt;
  line-height: 125%;
  text-transform: unset;
}
.as-restitution table th {
    text-align: center;
}

.as-restitution table td:nth-child(n+2) {
    text-align: center;
}
.as-study-controls {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 8px;
  right: 16px;
}
.as-restitution .as-comment {
  width: 100%;
  background: #f0f0f0;
  padding: 16px;
  font-size: 11pt;
  border-radius: 4px;
}
.as-insights-cta {
  background: #01efba;
  color: #273644;
  font-weight: 500;
  font-size: 11pt;
  padding: 10px 16px;
  text-align: center;
  border-radius: 6px;
}
.as-insights-cta:hover {
  color: #384755;
}
.as-insights-ghost-button {
  background:transparent;
  outline: 0;
  border: 0;
  color: #1a81dc;
  font-weight: 500;
}
.as-insights-ghost-button:hover {
  text-decoration: underline;
}
.as-insights-popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  width: 100vw;
  height: 100vh;

  .as-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.75);
    z-index: 0;
  }

  .as-content {
    background:white ;
    border-radius: 8px;
    padding: 24px 24px;
    z-index: 1;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 640px;
    overflow: auto;

    .as-title {
      font-size: 16pt;
      font-weight: bold;
      color: black;
      text-align: center;
    }

    ul {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      li {
        font-size: 12pt;
      }
    }
  }
}
